<div class="maintenance-banner__wrapper">
  <div class="container">
    <div class="maintenance-banner">
      <div class="maintenance-banner__content">
        <div class="maintenance-banner__title">{{ title() }}</div>
        <div class="maintenance-banner__text">{{ contents() }}</div>
      </div>
    </div>
  </div>
</div>
