<header class="header" [ngClass]="{ sticky: isSticky() }">
  <navigation-topbar [userCanStartSelfservice]="userCanStartSelfservice()" />
  <div class="navigation" [class.fixed]="false" *ngIf="router.url !== '/error'">
    <div class="container">
      <div class="navigation__flex" [class.terminated]="terminated()">
        <div class="navigation__text--wrapper">
          @if (productType()) {
            <span class="navigation__icon navigation__icon__{{ productType().toLowerCase() }}">
              <svg-icon
                src="assets/images/icons/{{ productType()?.toLowerCase() }}.svg"
                class="service-tile__icon"
              ></svg-icon>
              @if (firewallIconVisible() && !isFirewall()) {
                <span class="navigation-icon__overlap-square">
                  <svg-icon src="assets/images/icons/fw-overlap.svg" class="service-tile__overlap-icon"></svg-icon>
                </span>
              }
            </span>
          }
          @if (IPPrefixIconVisible()) {
            <span class="navigation__icon navigation__icon__ip_prefix">
              <svg-icon src="assets/images/icons/ip_prefix.svg" class="service-tile__icon"></svg-icon>
            </span>
          }
          @if (certIconVisible()) {
            <span class="navigation__icon navigation__icon__cert">
              <svg-icon src="assets/images/icons/cert.svg" class="service-tile__icon"></svg-icon>
            </span>
          }
          @if (domainsIconVisible()) {
            <span class="navigation__icon navigation__icon__domains">
              <svg-icon src="assets/images/icons/domains.svg" class="service-tile__icon"></svg-icon>
            </span>
          }
          <span class="navigation__text">
            @if (localIsChildRoute()) {
              <button (click)="goBack()" class="navigation__text--top navigation__back">
                <svg-icon src="assets/images/icons/icon-arrow-left.svg" class="back-button"> </svg-icon>
                {{ "Global.Action.Back" | translate }}
              </button>
            } @else if (routeTitle()) {
              <span class="navigation__text--top">{{ routeTitle() }}</span>
            }

            @if (!isDetailPage()) {
              <span class="navigation__text--main"> {{ forceTitle() ? forceTitle() : mainNavText() }}</span>
            }

            @if (isDetailPage() && detailData() !== undefined) {
              <span class="navigation__text--main">
                @if (!isWireless() && !terminated()) {
                  <svg-icon
                    src="assets/images/icons/status-{{ detailData().status | status_to_icon }}.svg"
                    class="table__status status-icon"
                  ></svg-icon>
                }
                @if (terminated()) {
                  <svg-icon
                    src="assets/images/icons/status-terminated.svg"
                    class="table__status status-icon"
                  ></svg-icon>
                }
                <span class="navigation__truncate">{{ detailData().title }}</span>
              </span>
            }
          </span>
        </div>
        <div class="navigation__right">
          <!-- this will contain the components for self-service and the overview button -->
          @if (userCanStartSelfservice()) {
            <selfservice-status />
          }
          <span class="navigation__divider"></span>
        </div>
      </div>
    </div>
    @if (subNavItems().length > 0) {
      <div class="navigation__sub">
        <div class="container">
          <div class="navigation__sub-flex">
            @for (item of subNavItems(); track $index) {
              <a href="" [routerLink]="item.link" routerLinkActive="active">
                {{ item.title | translate }}
              </a>
            }
          </div>
        </div>
      </div>
    }
  </div>
</header>
@if (showMaintenanceBanner()) {
  <maintenance-banner [maintenanceBanner]="maintenanceBanner()" />
}
