import { TranslateService } from "@ngx-translate/core";

const constructPageTitle = (translate: TranslateService, pageURL: string): Promise<string> => {
  const productType = pageURL.substring(pageURL.lastIndexOf("/") + 1);

  return new Promise((resolve) => {
    translate.get(`Subscription.${productType}.Name`).subscribe(resolve);
  });
};

export default constructPageTitle;
