import { Component, inject, input } from "@angular/core";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { Subscription } from "rxjs";
import { CustomerDisplay } from "../../../customer-display/customer-display";
import { ProfileMenuComponent } from "../../../profile-menu/profile-menu.component";
import { MatDialog, MatDialogState } from "@angular/material/dialog";

import { ChooseOrganisationDialogComponent } from "../../../../dialogs/choose-organisation/dialog";
import { AuthService } from "../../../../../services/AuthService";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DialogRolesComponent } from "../../../../dialogs/dialog-roles/dialog-roles";
import { ENV } from "../../../../../app/app.runtime";
import { DialogGenericComponent } from "../../../../dialogs/dialog-generic/dialog-generic";

@Component({
  selector: "navigation-topbar",
  templateUrl: "navigation-topbar.html",
  styleUrls: ["../../navigation.scss"],
  imports: [CustomerDisplay, RouterLink, RouterLinkActive, TranslateModule, ProfileMenuComponent],
})
export class NavigationTopbarComponent {
  private auth = inject(AuthService);
  private dialog = inject(MatDialog);
  private translate = inject(TranslateService);

  protected userCanStartSelfservice = input.required<boolean>();

  protected newsUrl = ENV.NWD_NEWS_URL;
  private roleEventSubscription: Subscription = Subscription.EMPTY;

  constructor() {
    if (this.roleEventSubscription.closed) {
      this.roleEventSubscription = this.auth.roleEvent.subscribe((roleEvent) => {
        const organizationName = this.auth.state.currentUser.organizationName;

        switch (roleEvent.event) {
          case "no-roles":
            this.openRolesDialog(
              this.translate.instant("Role.NoRoles.Title", {
                organisation: organizationName,
              }),
            );
            break;
          case "no-organization":
            const params = {
              data: {
                title: this.translate.instant("Dialog.OrganizationUnknown.Title"),
                message: this.translate.instant("Dialog.OrganizationUnknown.Message"),
                state: {},
                code: null,
              },
            };
            this.dialog.open(DialogGenericComponent, params);
            break;
          case "requirements-not-met":
            this.openRolesDialog(
              this.translate.instant("Role.NoAccess.Title", {
                productType: this.translate.instant(`Subscription.${roleEvent.productType}.Name`),
              }),
              roleEvent.need,
            );
            break;
        }
      });
    }
  }

  protected logOut() {
    this.auth.logout();
  }

  openChooserDialog() {
    const dialogRef = this.dialog.open(ChooseOrganisationDialogComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  openRolesDialog(dialogHeader?: string, need: string[] = []) {
    const organizationName = this.auth.state.currentUser.organizationName;
    const roles = this.auth.state.currentUser.roles ?? []; // fix sentry notifications about 'this.data.roles is undefined'.
    const defaultHeader = this.translate.instant("Role.ViewRoles.Title", {
      organisation: organizationName,
    });

    const openDialog = this.dialog.getDialogById("roles-dialog");
    if (openDialog?.getState() !== MatDialogState.OPEN) {
      this.dialog.open(DialogRolesComponent, {
        id: "roles-dialog",
        data: {
          roles,
          title: this.translate.instant("Navigation.Roles"),
          description: dialogHeader || defaultHeader,
          need,
        },
      });
    }
  }
}
