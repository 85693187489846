import { Component, computed, input } from "@angular/core";

export type MaintenanceBanner = {
  enabled: boolean;
  title: string;
  contents: string;
};

@Component({
  selector: "maintenance-banner",
  templateUrl: "maintenance-banner.html",
  styleUrls: ["../../navigation.scss"],
  imports: [],
})
export class MaintenanceBannerComponent {
  protected maintenanceBanner = input.required<MaintenanceBanner>();

  protected title = computed(() => this.maintenanceBanner().title);
  protected contents = computed(() => this.maintenanceBanner().contents);
}
