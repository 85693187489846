import { AfterViewInit, OnInit, OnDestroy, Component, isDevMode, signal, inject, input, computed } from "@angular/core";

import { Subscription as RxSubscription } from "rxjs";
import { AuthService } from "../../../services/AuthService";
import { Router, RouterEvent, ActivatedRoute, RouterLink, RouterLinkActive } from "@angular/router";
import { filter } from "rxjs/operators";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { constructWelcomeString, constructPageTitle } from "./helpers";
import { CommonModule, Location } from "@angular/common";
import { Subscription } from "rxjs";
import { NWD_VERSION } from "../../../version";
import { AngularSvgIconModule } from "angular-svg-icon";
import { StatusToIconPipe } from "../../../helpers/statusiconpipe/statusiconpipe";
import { SelfserviceStatusComponent } from "../../selfservice/selfservice-status/selfservice-status";
import { ClipboardModule } from "ngx-clipboard";
import { NavigationTopbarComponent } from "./components/navigation-topbar/navigation-topbar";
import { MaintenanceBanner, MaintenanceBannerComponent } from "./components/maintenance-banner/maintenance-banner";

@Component({
  selector: "navigation",
  templateUrl: "navigation.html",
  styleUrls: ["navigation.scss"],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    ClipboardModule,
    RouterLink,
    RouterLinkActive,
    SelfserviceStatusComponent,
    StatusToIconPipe,
    TranslateModule,
    NavigationTopbarComponent,
    MaintenanceBannerComponent,
  ],
})
export class NavigationComponent implements AfterViewInit, OnInit, OnDestroy {
  protected auth = inject(AuthService);
  protected router = inject(Router);
  protected translate = inject(TranslateService);
  private route = inject(ActivatedRoute);
  private location = inject(Location);

  protected readonly detailData = input<{ title: string; status: string }>();
  protected readonly firewallIconVisible = input(false);
  protected readonly forceTitle = input(null);
  protected readonly isSticky = input(true);
  protected readonly productType = input("");
  protected readonly subNavItems = input([]);
  protected readonly terminated = input(false);

  protected readonly isChildRoute = input(false);

  protected localIsChildRoute = signal(this.isChildRoute());
  protected localProductType = signal(this.productType());

  protected isFirewall = computed(() => this.productType().toLowerCase() === "fw");
  protected isWireless = computed(() => this.productType() === "Wireless");

  private pageURL = signal<string>("");

  private isDomainsPage = computed(() => this.pageURL().startsWith("/domains"));
  private detailPage = computed(() => this.pageURL().startsWith("/subscription/"));
  private ipPrefixesPage = computed(() => this.pageURL() === "/ip-prefixes");

  protected domainsIconVisible = computed(() => this.isDomainsPage());
  protected IPPrefixIconVisible = computed(() => this.ipPrefixesPage());
  protected certIconVisible = computed(() => this.pageURL() === "/cert" || this.pageURL() === "/cert/ddos");

  private isNewsPage = computed(() => this.pageURL().startsWith("/news"));

  protected isDetailPage = computed(() => this.detailPage());

  private hasHistory = signal(false);

  // temporary disabled maintenance banner in devMode
  protected showMaintenanceBanner = signal(this.auth.state.configuration.maintenanceBanner?.enabled && !isDevMode());

  protected maintenanceBanner = signal<MaintenanceBanner>(this.auth.state.configuration.maintenanceBanner);
  protected mainNavText = signal<string>("");
  protected routeTitle = signal<string | undefined>(undefined);
  protected nwdReleaseVersion = NWD_VERSION;
  protected userCanStartSelfservice = signal(false);

  private routerSubscription;
  private roleEventSubscription: Subscription = Subscription.EMPTY;
  private pageTitle = signal<string>("");
  private rxSubscriptions: RxSubscription[] = [];

  constructor() {
    this.routerSubscription = this.subscribeToRouter(this.router);
    this.hasHistory.set(this.router.navigated);
  }

  ngOnInit() {
    this.setMainNavText();
    this.rxSubscriptions.push(
      this.auth.userLoaded.subscribe((loaded) => {
        this.setMainNavText();
        this.userCanStartSelfservice.set(this.auth.canRequestSelfServiceDialog());
      }),
    );
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    this.rxSubscriptions.forEach((sub) => sub.unsubscribe());
    if (!this.roleEventSubscription.closed) {
      this.roleEventSubscription.unsubscribe();
    }
  }

  protected setRouteTitle(translationKey: string) {
    this.setRouteTitleOnly(translationKey);
    this.localIsChildRoute.set(false);
  }

  protected setRouteTitleOnly(translationKey: string) {
    this.translate.get(translationKey).subscribe((translation: string) => {
      this.routeTitle.set(translation);
    });
  }

  goBack() {
    window.scrollTo(0, 0);

    if (this.isDomainsPage()) {
      this.router.navigate(["/"]);
      return;
    }

    if (this.isNewsPage()) {
      this.location.back();
      return;
    }

    if (this.hasHistory()) {
      this.location.back();
    } else {
      this.router.navigate(["/"]);
    }
  }

  private subscribeToRouter(router) {
    return router.events
      .pipe(filter((e: RouterEvent): e is RouterEvent => e instanceof RouterEvent))
      .subscribe((e: RouterEvent) => {
        this.pageURL.set(e.url);
        this.pageTitle.set("");
        switch (this.pageURL()) {
          case "/":
          case "/dashboard":
            this.setRouteTitle("Navigation.Overview");
            break;
          case "/berichten":
            this.setRouteTitle("Navigation.Messages");
            break;
          case "/support":
            this.setRouteTitle("Navigation.Support");
            break;
          case "/storing-en-onderhoud":
            this.setRouteTitle("Global.Overview");
            this.pageTitle.set("Navigation.MalfunctionAndMaintenance");
            break;
          case "/diensten":
            this.setRouteTitle("Navigation.Subscriptions");
            break;
          case "/ip-prefixes":
            this.localIsChildRoute.set(true);
            this.pageTitle.set("Subscription.IP_PREFIX.Name");
            break;
          case "/notification-settings":
            this.localIsChildRoute.set(true);
            this.setRouteTitle("Global.Notifications");
            this.pageTitle.set("Navigation.NotificationSettings");
            break;
          case "/cert":
            this.localIsChildRoute.set(true);
            this.pageTitle.set("Subscription.Cert.Name");
            break;
          case "/cert/ddos":
            this.localIsChildRoute.set(true);
            this.pageTitle.set("Subscription.Cert.Name");
            break;
          default:
            this.localProductType.set(this.route.snapshot.params.productType || this.productType() || "");
            this.localIsChildRoute.set(true);
            this.routeTitle.set(null);

            if (this.pageURL().startsWith("/storing-en-onderhoud")) {
              this.setRouteTitle("Global.Overview");
              this.pageTitle.set("Navigation.MalfunctionAndMaintenance");
            }

            if (this.isDomainsPage()) {
              this.setRouteTitleOnly("Domains.Title");
              this.pageTitle.set("Domains.Title");
            }
        }
      });
  }

  private getMainNavText(): Promise<string> {
    if (this.localIsChildRoute()) {
      return constructPageTitle(this.translate, this.pageURL());
    } else if (this.pageTitle()) {
      return new Promise((resolve) => {
        this.translate.get(this.pageTitle()).subscribe(resolve);
      });
    }
    return constructWelcomeString(this.translate, this.auth.state.currentUser.displayName);
  }

  private setMainNavText() {
    // Fixme: ugly exception, fix logic
    if (this.pageURL() === "/ip-prefixes") {
      this.mainNavText.set("IP prefixes");
      return;
    }
    this.getMainNavText().then((value) => {
      this.mainNavText.set(value);
    });
  }
}
