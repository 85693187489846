<section class="topbar">
  <div class="container">
    <div class="topbar__flex">
      <a [routerLink]="['/']" class="logo"></a>
      <div class="navigation__items">
        <a
          [routerLink]="['/']"
          class="navigation__link"
          routerLinkActive="navigation__link--active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="navigation__link-inner">{{ "Navigation.Subscriptions" | translate }}</span>
        </a>
        <a
          [routerLink]="['/storing-en-onderhoud']"
          class="navigation__link"
          routerLinkActive="navigation__link--active"
        >
          <span class="navigation__link-inner">{{ "Navigation.MalfunctionAndMaintenance" | translate }}</span>
        </a>
        <a [routerLink]="['/support']" class="navigation__link" routerLinkActive="navigation__link--active">
          <span class="navigation__link-inner">{{ "Navigation.Support" | translate }}</span>
        </a>
        <a class="navigation__link">&nbsp;|&nbsp;</a>
        <a href="{{ newsUrl }}" class="navigation__link">
          <span class="navigation__link-inner">{{ "Navigation.News" | translate }}</span>
        </a>
      </div>

      <div class="topbar__items">
        <div class="topbar__item__wrapper">
          <customer-display (openSwitchDialog)="openChooserDialog()"></customer-display>
        </div>
        <profile-menu (openRolesDialog)="openRolesDialog()" (logout)="logOut()"></profile-menu>
      </div>
    </div>
  </div>
</section>
